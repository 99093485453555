.icon-marker {
    cursor: pointer;
    display: flex;
    width: 40px;
    height: 50px;
    color: linear-gradient(0deg, #fd6b00 0%, #ea6300 37.53%, #da5c00 67.22%, #c95500 100%);
    z-index: 0;
    position: relative;
    transform: translate(-50%, -80%);
}

.point-name {
    font-size: 10px;
    max-width: 15vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.point-name-container {
    position: absolute;
    left: 52%;
    top: 5%;
    z-index: 9;
}

.point-name-container:hover > a {
    text-decoration: none;
}

.hovered-item {
    position: absolute;
    z-index: 999;
    min-width: 20vw;
    max-width: 50vw;
}

.accordion-icon {
    background-color: rgba(247, 247, 247, 1);
    color: rgba(149, 149, 149, 1);
    padding: 4px;
    border-radius: 400px;
}

.button-width {
    width: 100%;
}
