.input-container {
    position: relative;
}

.input-icon {
    position: absolute;
    pointer-events: none;
    right: 4%;
    top: 19%;
    color: rgba(149, 149, 149, 1);
    width: 23px;
    height: 23px;
    z-index: 10;
    background: rgba(251, 251, 251, 1);
    padding: 2px;
}

.input::placeholder {
    font-size: 14px;
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(79, 79, 79, 1);
    transition: background-color 5000s ease-in-out 0s;
}
