.container-map {
    width: 100%;
    height: 100%;
    position: relative;
}

.container-map > ymaps {
    overflow: hidden;
}

.buttons-container {
    background-color: var(--color-background-light);
    padding: 2px;
    width: 92vw;
    margin-bottom: 13px;
    border-radius: 9px;
    margin-left: 16px;
    margin-right: 16px;
}

.active-button {
    background-color: var(--color-background-primary);
    color: var(--color-text-primary);
    width: 50%;
    border-radius: 7px;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
    font-weight: 600;
    padding-top: 8px;
    padding-bottom: 8px;
    letter-spacing: 2%;
    transition: all 0.5s ease;
}

.inactive-button {
    color: var(--color-text-secondary);
    width: 50%;
    font-weight: 500;
    padding-top: 8px;
    padding-bottom: 8px;
    letter-spacing: 2%;
}

.address-container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
    padding-bottom: 350px;
}

.address-item-container {
    padding-bottom: 8px;
    padding-top: 8px;
    &:not(:last-child) {
        border-bottom: 1px solid rgba(236, 236, 236, 1);
    }
}

.list-container {
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
}

.button {
    position: sticky;
    width: 100%;
    bottom: 5%;
}

.button-map {
    position: sticky;
    width: 92%;
    bottom: 5%;
    left: 4%;
}

.input::placeholder {
    font-size: 14px;
}
