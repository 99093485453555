.document-button {
    display: inline !important;
}

.container {
    display: none;
}

@media (min-width: 850px) {
    .container {
        position: fixed;
        bottom: 2%;
        left: 50%;
        width: max-content;
        max-width: 100vw;
        transform: translateX(-50%);
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        padding: 10px 8px;
        border-radius: 12px;
        border: none;
        background-color: var(--color-background-primary);
        justify-content: space-between;
        align-items: baseline;
    }
}
