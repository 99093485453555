.container-map {
    width: 100%;
    height: 100%;
}

.container-map > ymaps {
    overflow: hidden;
}

.item:hover {
    background-color: rgba(235, 241, 237, 1);
    color: rgba(26, 26, 26, 1);
    border-radius: 8px;
}

.icon-marker {
    cursor: pointer;
    display: flex;
    width: 40px;
    height: 50px;
    color: linear-gradient(0deg, #fd6b00 0%, #ea6300 37.53%, #da5c00 67.22%, #c95500 100%);
    z-index: 0;
    position: relative;
    transform: translate(-50%, -10%);
}

.point-name-container {
    position: absolute;
    left: 52%;
    top: 5%;
    z-index: 9;
}

.point-name-container:hover > a {
    text-decoration: none;
}

.point-name {
    font-size: 13px;
    max-width: 5vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.point-button {
    background-color: var(--color-background-brand-orange);
    color: var(--color-text-primary-inverse);
}

.point-button:hover {
    background-color: var(--color-background-brand-orange);
    color: var(--color-text-primary-inverse);
}

.cluster-name-container:hover > a {
    text-decoration: none;
}

.point-address {
    font-size: 10px;
    line-height: 10px;
    max-width: 8vw;
}

.address-container {
    position: absolute;
    z-index: 999;
    top: 5%;
    left: 3%;
    max-width: 15vw;
}

.address-list {
    max-height: 30vh;
    overflow-y: scroll;
}

.bordered-text {
    padding-bottom: 8px;
    border-bottom: 1px solid var(--color-border-primary);
}

.button-width {
    width: 100%;
}

.accordion-icon {
    background-color: rgba(247, 247, 247, 1);
    color: rgba(149, 149, 149, 1);
    padding: 4px;
    border-radius: 400px;
}

.portal > div {
    z-index: 100000;
    position: absolute;
    top: 0;
    right: 5%;
}

.hovered-item {
    position: absolute;
    z-index: 999;
    min-width: 20vw;
    max-width: 50vw;
}
