.bottomNav {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
    z-index: 1000;
}

.bottomNav:hover > a {
    text-decoration: none;
}

.navItem {
    flex: 1;
    text-align: center;
    padding-top: 2px;
    text-decoration: none;
}

.badge {
    position: absolute;
    top: -7%;
    right: 10%;
    background-color: var(--color-background-brand-green);
    border-radius: 10px;
    padding: 2px 4px;
    border: 1px solid var(--color-background-primary);
}

.badge-text {
    max-height: 1rem;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.07px;
    color: var(--color-text-primary-inverse);
}

@media (min-width: 850px) {
    .bottomNav {
        display: none;
    }
}
